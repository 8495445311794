import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

/**
 * ModuleLabel is a functional component that renders a label for a module.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The child elements to be rendered within the label.
 */
export const ModuleLabel = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.primary.offWhite,
        borderRadius: "16px",
        padding: "1rem",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};
