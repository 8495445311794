import { Box, Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
import { Buffer } from "../LogicGatesSVGs/Buffer";
import { useTheme } from "@emotion/react";
import { LogicGate } from "./LogicGate";

/**
 * BufferGate component is responsible for managing the state of the inputs and the output of the Buffer gate.
 * @param {Object} props - The props object
 * @param {Array<boolean>} props.input - The current state of the input for the Buffer gate
 * @param {function} props.toggleInput - The function to toggle the state of the input
 * @returns {JSX.Element}
 */
export const BufferGate = ({ input, toggleInput, disabled = false }) => {
  const theme = useTheme();
  return (
    <LogicGate>
      <Buffer input={input[0]} />
      <Box
        sx={{
          position: "absolute",
          top: `${49}%`,
          left: "21px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Checkbox
          disabled={disabled}
          color="primary"
          aria-label="toggle"
          onClick={() => toggleInput(0)}
          icon={<Typography variant="h3">A</Typography>}
          checkedIcon={
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.white }}
            >
              A
            </Typography>
          }
          checked={input[0]}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: `${50}%`,
          left: "83.4%",
          transform: "translate(-50%, -50%)",
        }}
      ></Box>
    </LogicGate>
  );
};
