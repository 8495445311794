import { BufferGate } from "../LogicGates/BufferGate";
import { NotGate } from "../LogicGates/NotGate";
import { AndGate } from "../LogicGates/AndGate";

/**
 * Object mapping logic gate types to their corresponding components.
 * Add more logic gate types here when required.
 */
const logicGate = {
  buffer: BufferGate,
  not: NotGate,
  and: AndGate,
};

/**
 * LogicGateWidget component is responsible for rendering the appropriate logic gate component based on the gate type.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The widget object.
 * @param {Array} props.widgetState - The current state of the widget.
 * @param {Function} props.setWidgetState - Function to update the widget state.
 */
export const LogicGateWidget = ({ widget, widgetState, setWidgetState }) => {
  /**
   * Function to toggle the input state at the given index.
   *
   * @param {number} index - The index of the input to toggle.
   */
  const handleToggleInput = (index) => {
    let copy = [...widgetState];
    copy[index] = !copy[index];
    setWidgetState(copy);
  };

  return logicGate[widget.gate_type]({
    input: widgetState,
    toggleInput: handleToggleInput,
  });
};
