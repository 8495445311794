import {
  Condition,
  MultipleChoice,
  Reading,
} from "../components/Action/widgetTypes";
import { Action, Step, Task } from "./taskSetup";

const step1 = Step("Repeat Instructions", 5, "", [
  Action("Repeat Instructions", [
    Reading([
      <>
        Sometimes computer programs needs to perform the same (or similar)
        operations more than once.
      </>,
      <>
        For example, let's say we want to fill our bucket with bananas, but we
        can only add one banana at a time.
      </>,
      <>
        If this were a computer program we'd have to tell the computer to add
        one banana over and over again until we have enough.
      </>,
    ]),
  ]),
  Action("Loops", [
    Reading([
      <>
        Writing the same instruction over and over again is not ideal. Lucky for
        us computer programs can use <strong>Loops</strong> to repeat a set of
        instructions.
        <br />
        <ul>
          <li>
            We have <strong>0</strong> bananas in our bucket.
          </li>
          <li>
            We want to have <strong>3</strong>.
          </li>
          <li>
            We can only add <strong>1</strong> at a time.
          </li>
        </ul>
        How many times will we need to add a banana to the bucket?
      </>,
    ]),
    MultipleChoice(["1", "2", "3"], "3"),
  ]),
  Action("Banana Bucket Computer Program", [
    Reading([
      <>
        If our banana bucket scenario were a computer program, which code
        instruction would we run to add a banana to the bucket?
      </>,
    ]),
    MultipleChoice(
      ["Add One Banana", "Subtract Three Bananas", "Do Nothing"],
      "Add One Banana"
    ),
  ]),
  Action("Banana Bucket Computer Program", [
    Reading([
      <>
        Computer programs use different <strong>variables</strong> to represent
        information. Let's say our program has a variable called{" "}
        <strong>Bananas</strong>. <br />
        <br />
        Each time we call the instruction <strong>Bananas + 1</strong>, we add
        one to our banana collection.
        <br />
        <br />
        How many times should we run <strong>Bananas + 1</strong> to get{" "}
        <strong>2</strong> bananas?
      </>,
    ]),
    MultipleChoice(["1", "2", "3"], "2"),
  ]),
  Action("Repeat Instructions", [
    Reading([
      <>
        Sometimes you have to repeat your instructions in order to reach your
        goal.
      </>,
      <>
        But repeating yourself can be tedious so luckily computer programs have
        a <strong>Control Structure</strong> that tells the computer to repeat a
        set of instructions for us: a <strong>Loop!</strong>
      </>,
      <>
        In this step we learned how to call repeating instructions, but in the
        next step we'll more about <strong>Loops</strong> and how loops decide
        when to run and when to stop.
      </>,
    ]),
  ]),
]);

const step2 = Step("If True Repeat", 5, "", [
  Action("To Loop Or Not To Loop", [
    Reading([
      <>
        In the last step we discussed how computer sometimes need to repeat
        instructions.
      </>,
      <>
        Writing the same piece of code over and over again is tedious so instead
        computer programs use <strong>Loops</strong>.
      </>,
      <>
        Loops will repeat a set of instructions multiple times, but loops need
        to know when to stop and when to keep going.
      </>,
      <>
        To do this, loops use <strong>Conditions</strong>.
      </>,
    ]),
  ]),
  Action("Carousel Logic", [
    Reading([
      <>
        Let's say we're riding a carousel that goes round and round{" "}
        <strong>5</strong> times. We've been around <strong>3</strong> times so
        far. Will the carousel go around again?
      </>,
    ]),
    MultipleChoice(["Yes", "No"], "Yes"),
  ]),
  Action("Carousel Logic", [
    Reading([
      <>
        When we've gone around the carousel <strong>5</strong> times and we
        reach the start again will the ride stop or keep going?
      </>,
    ]),
    MultipleChoice(["Keep Going", "Stop"], "Stop"),
  ]),
  Action("Carousel Logic", [
    Reading([
      <>
        Let's represent the number of times we've gone around with the variable{" "}
        <strong>i</strong>. Which condition should be <strong>True</strong> for
        the carousel to <strong>Stop</strong>?
      </>,
    ]),
    MultipleChoice(["i >= 5", "i < 5"], "i >= 5"),
  ]),
  Action("Carousel Logic", [
    Reading([
      <>
        Which condition should be <strong>True</strong> for the carousel to keep
        going?
      </>,
    ]),
    MultipleChoice(["i >= 5", "i < 5"], "i < 5"),
  ]),
  Action("Loop Logic", [
    Reading([
      <>
        In this step we discussed how loops use <strong>conditions</strong> to
        decide whether or not to repeat a set of instructions.
      </>,
      <>
        In order for a loop to continue, some condition needs to be{" "}
        <strong>True</strong>.
      </>,
      <>
        In the next step we'll learn more about how computer programmers use
        loops to control the flow of their programs.
      </>,
    ]),
  ]),
]);

const step3 = Step("While Loops", 5, "", [
  Action("While True", [
    Reading([
      <>In the previous step we discussed loops and conditions.</>,
      <>
        In this step we'll learn about a fundamental type of loop in computer
        programs: the <strong>While Loop</strong>.
      </>,
    ]),
  ]),
  Action("While True", [
    Reading([
      <>
        While loops are used to repeat a set of computer instructions{" "}
        <em>while</em> a condition is <strong>True</strong>. Let's say we're a
        dwarf collecting gold! We need at least <strong>200</strong> gold
        nuggets. Which condition should be <strong>true</strong> to keep mining?
      </>,
    ]),
    Condition(
      [
        "While gold nuggets is less than 200",
        "While gold nuggets is greater than 0",
      ],
      "Keep Mining",
      "That's Enough Gold",
      "While gold nuggets is less than 200"
    ),
  ]),
  Action("While True", [
    Reading([
      <>
        If we start with <strong>0</strong> gold nuggets and try to run the
        following condition:
        <br />
        <br />
        While gold nuggets is greater than 0: Keep Mining.
        <br />
        <br />
        How much gold would we mine?
      </>,
    ]),
    MultipleChoice(
      ["0 nuggets", "200 nuggets", "Infinite nuggets"],
      "0 nuggets"
    ),
  ]),
  Action("While True", [
    Reading([
      <>
        The condition of a while loop has to be true in first place before we
        can run the loop code! But there's another potential problem with this
        condition.
        <br />
        <br />
        If we start with <strong>1</strong> gold nugget and run the following
        condition:
        <br />
        <br />
        While gold nuggets is greater than 0: Keep Mining.
        <br />
        <br />
        How much gold would we mine?
      </>,
    ]),
    MultipleChoice(
      ["0 nuggets", "200 nuggets", "Infinite nuggets"],
      "Infinite nuggets"
    ),
  ]),
  Action("While True", [
    Reading([
      <>
        We have to be careful with our loops. If we choose the wrong condition
        our computer might just keep doing the same thing over and over again
        forever!
      </>,
      <>
        Just like our poor dwaven gold miner, infinite gold sounds nice but not
        if we have to mine endlessly.
      </>,
      <>
        Loops are a powerful and useful control structure that's used everywhere
        in computer programs but every loops needs to know when to stop.
      </>,
    ]),
  ]),
]);

export const iterationControl = Task("Iteration Control", "", [
  step1,
  step2,
  step3,
]);
