/*
Front-end utility functions to access the MC Pioneer API
*/

//const HOST = process.env.REACT_APP_HOST;

/**
 * Fetches the user data from the MC Pioneer API.
 *
 * @param {string} userId - The ID of the user.
 * @returns {Promise<object>} The user data.
 */
export async function getUser(userId) {
  try {
    let response = await fetch(`/api/users/${userId}`);
    if (response.ok) {
      let data = await response.json();
      return data;
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.log("Get User Fetch Error: ", error);
    return false;
  }
}

/**
 * Creates a new user in the MC Pioneer API.
 *
 * @param {string} userId - The ID of the user to be created.
 * @returns {Promise<object>} The data of the newly created user.
 */
export async function createUser(userId) {
  try {
    let params = new URLSearchParams();
    params.append("userId", userId);

    let response = await fetch(`/api/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    });

    if (response.ok) {
      let data = await response.json();
      return data;
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.log("Error creating user: ", error);
  }
}

/**
 * Completes a task step by sending a POST request to the MC API.
 *
 * @param {string} user_id - The ID of the user.
 * @param {string} unique_task_key - The unique key of the task.
 * @param {string} partner_task_reference - The reference of the partner task.
 * @param {number} step_number - The number of the step to complete.
 */
export async function completeTaskStep(
  user_id,
  unique_task_key,
  partner_task_reference,
  step_number
) {
  let params = new URLSearchParams();
  params.append("unique_task_key", unique_task_key);
  params.append("task_reference", partner_task_reference);
  params.append("step", parseInt(step_number));

  console.log(`complete task step params: ${params}`);

  try {
    await fetch(`/api/users/${user_id}/complete-step`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    });
  } catch (error) {
    console.log("Complete task step error: ", error);
  }
}
