import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

/**
 * Header component that accepts children props and renders them inside a Box component at the top of the page.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the Header.
 */
export const Header = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "1rem",
        height: "4.5rem",
        width: "100%",
        border: `1px solid ${theme.palette.primary.lightGray}`,
        backgroundColor: theme.palette.primary.white,
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      {children}
    </Box>
  );
};
