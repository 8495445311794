import { MultipleChoiceWidget } from "./MultipleChoiceWidget";
import { BinaryWidget } from "./BinaryWidget";
import { GraphicSwitchWidget } from "./GraphicSwitchWidget";
import { LogicGateWidget } from "./LogicGateWidget";
import { ModuleDiagramWidget } from "./ModuleDiagramWidget";
import { TruthTableWidget } from "./TruthTableWidget";
import { ModuleOrderWidget } from "./ModuleOrderWidget/ModuleOrderWidget";
import { ReadingWidget } from "./ReadingWidget/ReadingWidget";
import { ConditionWidget } from "./ConditionWidget/ConditionWidget";
import { LoopWidget } from "./LoopWidget/LoopWidget";

export const module_diagram = 0;
export const binary = 1;
export const graphic_switch = 2;
export const logic_gate = 3;
export const multiple_choice = 4;
export const truth_table = 5;
export const module_order = 6;
export const reading = 7;
export const condition = 8;
export const loop = 9;

export const GetWidget = (widgetType) => {
  if (widgetType === module_diagram) return ModuleDiagramWidget;
  if (widgetType === binary) return BinaryWidget;
  if (widgetType === graphic_switch) return GraphicSwitchWidget;
  if (widgetType === logic_gate) return LogicGateWidget;
  if (widgetType === multiple_choice) return MultipleChoiceWidget;
  if (widgetType === truth_table) return TruthTableWidget;
  if (widgetType === module_order) return ModuleOrderWidget;
  if (widgetType === reading) return ReadingWidget;
  if (widgetType === condition) return ConditionWidget;
  if (widgetType === loop) return LoopWidget;
};

export const Loop = (conditionOptions, loopModule, answer) => {
  return {
    type: loop,
    defaultState: { condition: false, loopModule: loopModule },
    answerState: { condition: answer, loopModule: loopModule },
    options: conditionOptions,
  };
};

export const Condition = (
  conditionOptions,
  trueModule,
  falseModule,
  answer
) => {
  return {
    type: condition,
    defaultState: { condition: false, true: trueModule, false: falseModule },
    answerState: { condition: answer, true: trueModule, false: falseModule },
    options: conditionOptions,
  };
};

export const Reading = (content) => {
  return {
    type: reading,
    defaultState: 0,
    answerState: content.length - 1,
    content: content,
    submitText: "Next",
  };
};

export const ModuleOrder = (modules, answer) => {
  return {
    type: module_order,
    defaultState: modules,
    answerState: answer,
  };
};

/**
 * Creates a GraphicSwitch widget configuration object.
 *
 * @param {string} graphic - The graphic (key) to be used in the widget.
 * @param {boolean} answer - The correct state of the switch.
 * @param {boolean} [defaultState=false] - The initial state of the switch.
 * @returns {Object} The GraphicSwitch widget configuration object.
 */
export const GraphicSwitch = (graphic, answer, defaultState = false) => {
  return {
    type: graphic_switch,
    graphic: graphic,
    defaultState: defaultState,
    answerState: answer,
  };
};

/**
 * Creates a LogicGate widget configuration object.
 *
 * @param {string} type - The type of logic gate (e.g., "buffer", "not", "and").
 * @param {boolean|array} answer - The correct state of the logic gate. For "buffer" and "not" types, this should be a boolean. For "and" type, this should be an array of booleans.
 * @returns {Object} The LogicGate widget configuration object.
 */
export const LogicGate = (type, answer, defaultState = undefined) => {
  type = type.toLowerCase();
  if (type === "buffer") {
    return {
      type: logic_gate,
      gate_type: "buffer",
      defaultState: defaultState ? defaultState : [false],
      answerState: [answer],
    };
  }
  if (type === "not") {
    return {
      type: logic_gate,
      gate_type: "not",
      defaultState: defaultState ? defaultState : [false],
      answerState: [answer],
    };
  }
  if (type === "and") {
    return {
      type: logic_gate,
      gate_type: "and",
      defaultState: defaultState ? defaultState : [false, false],
      answerState: answer,
    };
  }
};
/**
 * Creates a TruthTable widget configuration object.
 *
 * @param {Array<Array>} table - The initial state of the truth table.
 * @param {Array<Array>} answer - The correct state of the truth table.
 * @returns {Object} The TruthTable widget configuration object.
 */
export const TruthTable = (table, answer) => {
  return {
    type: truth_table,
    defaultState: table,
    answerState: answer,
  };
};

/**
 * Creates a Binary widget configuration object
 * @param {Array} bits - array of bits
 * @param {Array} answer - correct arrangement of bits
 * @param {boolean} labelBits - whether to label bits
 * @returns
 */
export const Binary = (bits, answer, labelBits = true) => {
  return {
    type: binary,
    defaultState: bits,
    answerState: answer,
    labelBits: labelBits,
  };
};

/**
 * Creates a multiple choice widget configuration object
 * @param {Array} options - array of options to display
 * @param {string} answer - correction answer
 * @returns
 */
export const MultipleChoice = (options, answer) => {
  return {
    type: multiple_choice,
    options: options,
    defaultState: false,
    answerState: answer,
  };
};

/**
 * Creates a Sequence Diagram Widget configuration object
 * @param  {...any} args - Modules followed by answer state
 * @returns
 */
export const SequenceDiagram = (...args) => {
  return ModuleDiagram("sequence", ...args);
};

/**
 * Creates a Selection Diagram Widget configuration object
 * @param  {...any} args - Modules followed by answer state
 * @returns
 */
export const SelectionDiagram = (...args) => {
  return ModuleDiagram("selection", ...args);
};

/**
 * Create a condition diagram widget configutation object
 * @param {Array/string} modules - modules
 * @param {Array} answerState - correct configuration of inputs / state
 * @returns
 */
export const ConditionDiagram = (modules, answerState) => {
  return {
    type: module_diagram,
    diagram: "condition",
    modules: [modules],
    defaultState: Array.isArray(modules) ? ["?"] : modules,
    answerState: Array.isArray(answerState) ? answerState : [answerState],
  };
};

/**
 * Create an iteration diagram widget configuration object.
 * @param  {...any} args - modules followed by answer state
 * @returns
 */
export const IterationDiagram = (...args) => {
  return ModuleDiagram("iteration", ...args);
};

/**
 * Module diagram template
 * @param  {...any} args
 * @returns
 */
const ModuleDiagram = (...args) => {
  let type, moduleA, moduleB, moduleC, answer;
  if (args.length === 5) {
    [type, moduleA, moduleB, moduleC, answer] = args;
    return {
      type: module_diagram,
      diagram: type,
      modules: [moduleA, moduleB, moduleC],
      defaultState: [
        Array.isArray(moduleA) ? "?" : moduleA,
        Array.isArray(moduleB) ? "?" : moduleB,
        Array.isArray(moduleC) ? "?" : moduleC,
      ],
      answerState: answer,
    };
  } else if (args.length === 4) {
    [type, moduleA, moduleB, answer] = args;
    return {
      type: module_diagram,
      diagram: type,
      modules: [moduleA, moduleB],
      defaultState: [
        Array.isArray(moduleA) ? "?" : moduleA,
        Array.isArray(moduleB) ? "?" : moduleB,
      ],
      answerState: answer,
    };
  } else {
    [type, moduleA, answer] = args;
    return {
      type: module_diagram,
      diagram: type,
      modules: [moduleA],
      defaultState: [Array.isArray(moduleA) ? "?" : moduleA],
      answerState: answer,
    };
  }
};
