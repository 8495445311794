import { setStepNumber } from "../../context/ASAQueryParams";
import { Step } from "../Step/Step";
import { TaskComplete } from "./TaskComplete";
import { ASAParamsContext } from "../../context/ASAQueryParams";
import { useContext, useEffect } from "react";
import { completeTaskStep, createUser, getUser } from "../../mcpAPI";
import { taskRoutes } from "../../tasks/tasks";
import { TaskStart } from "./TaskStart";

export const Task = () => {
  const { userId, uniqueTaskKey, step, partnerTaskReference } =
    useContext(ASAParamsContext);
  const steps = taskRoutes[partnerTaskReference].steps;
  const stepNumber = parseInt(step);

  useEffect(() => {
    const userRoutingFlow = async (userId, taskReference) => {
      let user = await getUser(userId);
      if (user) {
      } else {
        user = await createUser(userId);
      }

      let taskProgress = user.taskProgress[taskReference];
      if (!taskProgress) setStepNumber(1);
      else {
        if (parseInt(taskProgress) + 1 < stepNumber) {
          setStepNumber(parseInt(taskProgress) + 1);
        }
      }
    };

    if (userId) userRoutingFlow(userId, partnerTaskReference);
  }, [userId, partnerTaskReference, stepNumber]);

  const handleCompleteStep = async () => {
    await completeTaskStep(
      userId,
      uniqueTaskKey,
      partnerTaskReference,
      stepNumber
    );
  };

  const handleNextStep = () => {
    setStepNumber(stepNumber + 1);
  };

  const handleStartTask = () => {
    setStepNumber(1);
  };

  if (!step)
    return (
      <TaskStart
        task={taskRoutes[partnerTaskReference]}
        onStart={handleStartTask}
      />
    );
  if (step > steps.length) return <TaskComplete></TaskComplete>;

  return (
    <Step
      key={step}
      step={steps[stepNumber - 1]}
      onComplete={handleCompleteStep}
      onNext={handleNextStep}
    />
  );
};
