/**
 * Creates a Task object.
 *
 * @param {string} name - The name of the task.
 * @param {string} description - The description of the task.
 * @param {Array} steps - The steps of the task.
 * @returns {Object} The Task object.
 */
export const Task = (name, description, steps) => {
  steps = steps.map((step, index) => {
    return {
      ...step,
      number: index + 1,
    };
  });
  return {
    name: name,
    description: description,
    steps: steps,
  };
};

/**
 * Creates a Step object.
 *
 * @param {string} name - The name of the step.
 * @param {number} estimatedTime - The estimated time to complete the step.
 * @param {string} description - The description of the step.
 * @param {Array} actions - The actions of the step.
 * @returns {Object} The Step object.
 */
export const Step = (name, estimatedTime, description, actions) => {
  return {
    info: {
      name: name,
      estimatedTime: estimatedTime,
      description: description,
    },
    actions: actions,
  };
};

/**
 * Creates an Action object.
 *
 * @param {string} name - The name of the action.
 * @param {Array} widgets - The widgets associated with the action.
 * @returns {Object} The Action object.
 */
export const Action = (name, widgets) => {
  return {
    name: name,
    widgets: widgets,
  };
};
