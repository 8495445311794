import { Box } from "@mui/material";

/**
 * PageContentSlim component that accepts children props and renders them inside a Box component.
 * Used to determine content width based on screen size.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the PageContent.
 */

export const PageContentSlim = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        width: {
          xs: "100%",
          sm: "50%",
          md: "25%",
        },
      }}
    >
      {children}
    </Box>
  );
};
