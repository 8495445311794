import { Box, Typography } from "@mui/material";
import compassPNG from "../assets/Compass (1) 1.png";
import logo from "../assets/Mastery Coding Logo_Main Logo 1.png";
import { Footer } from "../components/Page/Footer";
import { CustomButton } from "../components/CustomButton";
import { PageContentSlim } from "../components/Page/PageContentSlim";

export const Homepage = ({ onRoute }) => {
  return (
    <PageContentSlim>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "80vh",
        }}
      >
        <Box display="flex" justifyContent="center">
          <img src={logo} alt="Mastery Coding Logo" />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box display="flex" justifyContent="center">
            <img src={compassPNG} alt="Compass" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <Typography variant="h1_blue" align="center">
              Welcome to Pioneer
            </Typography>
            <Typography variant="body" align="center">
              From counting in Binary to encryption, unlock exciting skills and
              knowledge in computer science and technology!
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer>
        <PageContentSlim>
          <Box sx={{ padding: "1rem" }}>
            <CustomButton text="Get Started" enabled={true} onClick={onRoute} />
          </Box>
        </PageContentSlim>
      </Footer>
    </PageContentSlim>
  );
};
