import { Box, Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
import { Not } from "../LogicGatesSVGs/Not";
import { useTheme } from "@emotion/react";
import { LogicGate } from "./LogicGate";

/**
 * NotGate component
 * @param {Object} props - The properties passed to the component
 * @param {number} props.inputCount - The number of inputs for the logic gate, default is 1
 * @param {function} props.setAnswer - Function to set the answer
 * @param {boolean} props.inputOverride - Boolean used to override input if not undefined
 * @returns {JSX.Element}
 */
export const NotGate = ({ input, toggleInput, disabled = false }) => {
  const theme = useTheme();

  return (
    <LogicGate>
      <Not input={input[0]} />
      <Box
        sx={{
          position: "absolute",
          top: `${50}%`,
          left: "20px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Checkbox
          disabled={disabled}
          color="primary"
          aria-label="toggle"
          onClick={() => toggleInput(0)}
          icon={<Typography variant="h3">A</Typography>}
          checkedIcon={
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.white }}
            >
              A
            </Typography>
          }
          checked={input[0]}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: `${50}%`,
          left: "83.4%",
          transform: "translate(-50%, -50%)",
        }}
      ></Box>
    </LogicGate>
  );
};
