import { Action, Step, Task } from "./taskSetup";
import {
  GraphicSwitch,
  LogicGate,
  TruthTable,
  Reading,
} from "../components/Action/widgetTypes";

const step1 = Step("Pass it along", 5, "", [
  Action("ON / OFF", [
    Reading([
      <>
        Computers use electric signals which can either be:
        <ul>
          <li>
            <strong>ON</strong>
          </li>
          <li>
            <strong>OFF</strong>
          </li>
        </ul>
        Use the switch below to turn the light <strong>ON</strong>
      </>,
    ]),
    GraphicSwitch("transistor", true),
  ]),
  Action("Logic Gates", [
    Reading([
      <>
        Computers use <strong>Logic Gates</strong> to decide what to do with
        these electric signals. The simplest gate is called a{" "}
        <strong>Buffer</strong>. Try turning <strong>ON</strong> the{" "}
        <strong>Output</strong>, by switching <strong>Input A</strong>
      </>,
    ]),
    LogicGate("buffer", true),
  ]),
  Action("Truth Tables", [
    Reading([
      <>
        Computers represent on and off with 0 and 1. For the{" "}
        <strong>Buffer Gate</strong>, if the input is <strong>OFF</strong>, what
        will the <strong>Output</strong> be?
      </>,
    ]),
    TruthTable([[0, 0]], [[0, 0]]),
  ]),

  Action("Truth Tables", [
    Reading([
      <>
        For the <strong>Buffer Gate</strong>, if the input is{" "}
        <strong>ON</strong>, what will the <strong>Output</strong> be?
      </>,
    ]),
    TruthTable([[1, 0]], [[1, 1]]),
  ]),
]);

const step2 = Step("NOT", 5, "", [
  Action("NOT Gate", [
    Reading([
      <>
        A <strong>NOT</strong> gate outputs the opposite of its input. Turn{" "}
        <strong>ON</strong> the output.
      </>,
    ]),
    LogicGate("not", false, [true]),
  ]),
  Action("NOT Gate", [
    Reading([
      <>
        Turn <strong>OFF</strong> the output.
      </>,
    ]),
    LogicGate("not", true),
  ]),
  Action("NOT Gate Truth Table", [
    Reading([
      <>
        For <strong>NOT</strong> gates, if the input is <strong>OFF</strong>,
        what will the output be?
      </>,
    ]),
    TruthTable([[0, 0]], [[0, 1]]),
  ]),
  Action("NOT Gate Truth Table", [
    Reading([
      <>
        For <strong>NOT</strong> gates, if the input is <strong>ON</strong>,
        what will the output be?
      </>,
    ]),
    TruthTable([[1, 1]], [[1, 0]]),
  ]),
]);

const step3 = Step("AND", 5, "", [
  Action("AND Gate", [
    Reading([
      <>
        An <strong>AND</strong> gate has two inputs (A and B) and one output.
        AND gates output <strong>ON</strong>, when <strong>both</strong>, inputs
        are on. Turn <strong>ON</strong>, the output.
      </>,
    ]),
    LogicGate("and", [true, true]),
  ]),
  Action("AND Gate", [
    Reading([
      <>
        Both inputs need to be <strong>ON</strong>, to turn on the output.
        Select the correct output based on the inputs below.
      </>,
    ]),
    TruthTable([[0, 0, 1]], [[0, 0, 0]]),
  ]),
  Action("AND Gate", [
    Reading([
      <>
        What if only one of the inputs is on? Select the correct output based on
        the inputs below.
      </>,
    ]),
    TruthTable([[1, 0, 0]], [[1, 0, 0]]),
  ]),
  Action("AND Gate", [
    Reading([<>Select the correct output based on the inputs below.</>]),
    TruthTable([[1, 1, 0]], [[1, 1, 1]]),
  ]),
]);

export const logicGates = Task("Logic Gates", "", [step1, step2, step3]);
