import { DiagramOverlay } from "../ControlStructures/DiagramOverlay";
import { SequenceDiagramSVG } from "../ControlStructures/Diagrams/SequenceDiagramSVG";
import { OperationSelector } from "../ControlStructures/OperationSelector";
import { DiagramLabel } from "../ControlStructures/DiagramLabel";
import { useState, useEffect } from "react";
import { OptionModal } from "../ControlStructures/OptionModal";
import { ConditionDiagramSVG } from "../ControlStructures/Diagrams/ConditionDiagramSVG";
import { SelectionDiagramSVG } from "../ControlStructures/Diagrams/SelectionDiagramSVG";
import { LoopDiagramSVG } from "../ControlStructures/Diagrams/LoopDiagramSVG";

/**
 * ModuleDiagramWidget component is responsible for rendering the appropriate diagram based on the widget type.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The widget object containing the diagram type and modules.
 * @param {Array} props.widgetState - The current state of the widget.
 * @param {Function} props.setWidgetState - Function to update the widget state.
 */
export const ModuleDiagramWidget = ({
  widget,
  widgetState,
  setWidgetState,
}) => {
  const type = widget.diagram;
  const modules = widget.modules;
  let modulePositions;
  switch (type) {
    case "sequence":
      modulePositions = [
        [11, 50],
        [50, 50],
        [90, 50],
      ];
      break;
    case "selection":
      modulePositions = [
        [21, 27.5],
        [85, 27.5],
        [85, 80],
      ];
      break;
    case "iteration":
      modulePositions = [
        [16, 49],
        [62.5, 48],
        [85, 80],
      ];
      break;
    case "condition":
      modulePositions = [[50, 50]];
      break;
    default:
      modulePositions = [
        [11, 50],
        [50, 50],
        [90, 50],
      ];
      break;
  }

  const [modalIndex, setModalIndex] = useState(-1);
  const [modalOpen, setModalOpen] = useState(false);

  const setSelectedModule = (index, value) => {
    let copy = [...widgetState];
    copy[index] = value;
    setWidgetState(copy);
  };

  const modalSetOperation = (value) => {
    setSelectedModule(modalIndex, value);
    setModalOpen(false);
  };

  const openModal = (index) => {
    setModalIndex(index);
    setModalOpen(true);
  };

  const handleClickOutside = (event) => {
    if (event.target.tagName === "BUTTON") {
    } else {
      if (modalOpen) {
        setModalOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <DiagramOverlay>
      {type.toLowerCase() === "sequence" && (
        <SequenceDiagramSVG
          selectModules={modules.map((module) => Array.isArray(module))}
        />
      )}
      {type.toLowerCase() === "selection" && (
        <SelectionDiagramSVG
          selectModules={modules.map((module) => Array.isArray(module))}
        />
      )}
      {type.toLowerCase() === "iteration" && (
        <LoopDiagramSVG
          selectModules={modules.map((module) => Array.isArray(module))}
        />
      )}
      {type.toLowerCase() === "condition" && (
        <ConditionDiagramSVG
          selectModules={modules.map((module) => Array.isArray(module))}
        />
      )}

      {widgetState.map((module, index) => {
        return Array.isArray(modules[index]) ? (
          <OperationSelector
            top={modulePositions[index][0]}
            left={modulePositions[index][1]}
            operation={module}
            onClick={() => openModal(index)}
          />
        ) : (
          <DiagramLabel
            top={modulePositions[index][0]}
            left={modulePositions[index][1]}
            text={module}
          />
        );
      })}

      {modalOpen && (
        <OptionModal
          options={modules[modalIndex]}
          setOption={modalSetOperation}
          instruction={`Select operation ${modalIndex + 1}.`}
        />
      )}
    </DiagramOverlay>
  );
};
