import {
  Condition,
  MultipleChoice,
  Reading,
} from "../components/Action/widgetTypes";
import { Action, Step, Task } from "./taskSetup";

const step1 = Step("Branches", 5, "", [
  Action("Branches", [
    Reading([
      <>Computer programs are made up of multiple instructions.</>,
      <>
        By default the program will execute each instruction in a set{" "}
        <strong>sequence</strong>: one after another.
      </>,
      <>
        But sometimes, the program needs to make a decision about which
        instruction to execute next.
      </>,
      <>
        We can think of these decision points like <strong>branches</strong>{" "}
        where the execution of the program goes in different directions.
      </>,
    ]),
  ]),
  Action("Fork In The Road", [
    Reading([
      <>
        A traveler comes to a fork in the road. One path leads to the left, and
        the other to the right. The traveler must go forward, how many options
        do they have?
      </>,
    ]),
    MultipleChoice(["1", "2", "3"], "2"),
  ]),
  Action("Fork In The Road", [
    Reading([
      <>
        At the fork there is a signpost with two arrows:
        <ul>
          <li>Left: The Dark Swamp</li>
          <li>Right: The Castle</li>
        </ul>
        If our adventurer wants to go to the castle, which path will they
        choose?
      </>,
    ]),
    MultipleChoice(["Left", "Right"], "Right"),
  ]),
  Action("Decisions", [
    Reading([
      <>The traveler has to make a decision: go left or go right.</>,
      <>
        But they won't make this decision willy nilly. Instead they'll think
        about what their goal is and then assess the situation.
      </>,
      <>
        The path before them branches in two directions: one goes to the castle,
        the other goes to the swamp.
      </>,
      <>
        Computers would think about the decision like a{" "}
        <strong>True or False</strong> question.
      </>,
    ]),
  ]),
  Action("True or False", [
    Reading(["True of False: The traveler wants to go to the castle."]),
    MultipleChoice(["True", "False"], "True"),
  ]),
  Action("Left or Right", [
    Reading([
      <>
        If the following statement is <strong>True</strong>, which path should
        the traveler take?
        <ul>
          <li>The traveler wants to go to the castle</li>
        </ul>
      </>,
    ]),
    MultipleChoice(["Left: Swamp", "Right: Castle"], "Right: Castle"),
  ]),
  Action("Branching Logic", [
    Reading([
      <>
        In Computer programs there can be multiple{" "}
        <strong>decision points</strong> where the program might go in different
        directions.
      </>,
      <>
        We can think of these decision points like <strong>branches</strong>{" "}
        where the program can go left or right.
      </>,
      <>
        The program will ask a <strong>True or False</strong> question. If the
        answer is <strong>True</strong>, it will go one way. If the answer is{" "}
        <strong>False</strong> it will go the other way.
      </>,
      <>
        The True or False questions are called <strong>Conditions</strong> and
        we'll learn more about them in the next step.
      </>,
    ]),
  ]),
]);

const step2 = Step("Conditions", 5, "", [
  Action("Conditions", [
    Reading([
      <>
        Computer programs use <strong>Conditions</strong> to decide what to do
        next when the program reaches a decision point.
      </>,
      <>
        Conditions are statements that can either be{" "}
        <strong>True or False</strong>.
      </>,
      <>
        For example:
        <ul>
          <li>
            <strong>10 is greater than 5</strong>
          </li>
        </ul>
        The <em>condition</em> above is <strong>True</strong>.
      </>,
      <>
        Let's take a look at some more conditions and what computers might do
        with them.
      </>,
    ]),
  ]),
  Action("True Conditions", [
    Reading([
      <>
        Which of the following conditions would be <strong>True</strong>?
      </>,
    ]),
    MultipleChoice(
      ["10 is less than 5", "5 equals 2 x 2", "3 is less than 4"],
      "3 is less than 4"
    ),
  ]),
  Action("False Conditions", [
    Reading([
      <>
        Which of the following conditions would be <strong>False</strong>?
      </>,
    ]),
    MultipleChoice(
      ["10 is greater than 5", "5 equals 4 + 1", "3 is greater than 4"],
      "3 is greater than 4"
    ),
  ]),
  Action("What To Do Next?", [
    Reading([
      <>
        Let's imagine we're trying to decide whether or not to eat some food.
        Which condition should be <strong>True</strong>?
      </>,
    ]),
    Condition(
      ["Is Hungry", "Is Sleepy", "Is At School"],
      "Eat Food",
      "Don't Eat",
      "Is Hungry"
    ),
  ]),
  Action("What To Do Next?", [
    Reading([
      <>
        Maybe we're hungry, but now we need to decide what to eat. Which
        condition matches the true/false options below?
      </>,
    ]),
    Condition(
      ["Is At School", "Likes Sugar", "Is In Bed"],
      "Candy Bar",
      "Salad",
      "Likes Sugar"
    ),
  ]),
  Action("Conditions", [
    Reading([
      <>
        Computer use <strong>Conditions</strong> to decide what to do next.
      </>,
      <>
        Conditions can either be <strong>True or False</strong>.
      </>,
      <>
        Each time the computer program encounters a conditional statement, it's
        kind of like a <strong>Branch</strong>: The program can go one way or
        the other depending on the condition.
      </>,
      <>
        Things get really interesting when our conditions start using
        information or values that can change while the program is running. But
        we'll save that for the next step.
      </>,
    ]),
  ]),
]);

const step3 = Step("Comparison", 5, "", [
  Action("Comparing Two Values", [
    Reading([
      <>
        Most conditions in computer programs involve <strong>Comparing</strong>{" "}
        two values.
      </>,
      <>
        For example, if we were comparing <strong>2</strong> and{" "}
        <strong>3</strong>, we might ask <em>Is 2 greater than 3?</em>
      </>,
      <>
        To which the answer would of course be <strong>False!</strong>{" "}
        <em>2 is not greater than 3.</em>
      </>,
      <>
        But things get more interesting when instead of using set numbers like 2
        and 3, we start using values that can change, or{" "}
        <strong>variables</strong> we don't know the value of.
      </>,
    ]),
  ]),
  Action("Variables", [
    Reading([
      <>
        Similar to math, computers use variables to represent information that
        might change while the program is running. Let's say we have a variable{" "}
        <strong>X</strong>, which is equal to 2 + 2. What's the value of X?
      </>,
    ]),
    MultipleChoice(["2", "3", "4"], "4"),
  ]),
  Action("Variables and Conditions", [
    Reading([
      <>
        Let's try using a variable in one of our conditions. If we have a
        variable called <strong>Bananas</strong> which is currently equal to{" "}
        <strong>15</strong>, which condition below would be{" "}
        <strong>True</strong>?
      </>,
    ]),
    Condition(
      [
        "Bananas is greater than 20",
        "Bananas is less than 13",
        "Bananas is equal to 15",
      ],
      "That's Enough Bananas",
      "Never Enough Bananas",
      "Bananas is equal to 15"
    ),
  ]),
  Action("Variables, Conditions, and More Bananas", [
    Reading([
      <>
        Let's say we have 5 bananas but we want 15 exactly. Which condition
        would we choose to get more bananas.
      </>,
    ]),
    Condition(
      [
        "Bananas is greater than 20",
        "Bananas is greater than 15",
        "Bananas is less than 15",
      ],
      "Get More Bananas",
      "No More Bananas",
      "Bananas is less than 15"
    ),
  ]),
  Action("Comparison In a Nutshell", [
    Reading([
      <>
        In this step we learned a little bit about how computer can compare
        different values when making decisions.
      </>,
      <>
        For instance, if we want a certain number of bananas we might compare
        the amount of bananas we currently have with the amount we need.
      </>,
      <>If we have enough, we'll stop collecting bananas.</>,
      <>If we don't have enough, we'll keep collecting!</>,
      <>
        Just like our banana quest, computers use comparison and questions like
        this all the time to decide what to do when the reach a{" "}
        <strong>Branch</strong>!
      </>,
    ]),
  ]),
]);

export const selectionControl = Task("Selection Control", "", [
  step1,
  step2,
  step3,
]);
