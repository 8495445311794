import { Box, Typography } from "@mui/material";
import { ModuleMoveable } from "./ModuleMoveable";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTheme } from "@emotion/react";

/**
 * ModuleOrderWidget is a functional component that renders a widget for ordering modules.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The configuration of the widget.
 * @param {Object} props.widgetState - The current state of the widget.
 * @param {function} props.setWidgetState - A function to update the state of the widget.
 */
export const ModuleOrderWidget = ({ widget, widgetState, setWidgetState }) => {
  const theme = useTheme();
  const modules = widgetState;
  const setModules = (newValue) => {
    setWidgetState(newValue);
  };

  const moveUp = (index) => {
    let copy = [...modules];
    let moveDownModule = modules[index - 1];
    let moveUpModule = modules[index];
    copy[index - 1] = moveUpModule;
    copy[index] = moveDownModule;
    setModules(copy);
  };

  const moveDown = (index) => {
    let copy = [...modules];
    let moveDownModule = modules[index];
    let moveUpModule = modules[index + 1];
    copy[index] = moveUpModule;
    copy[index + 1] = moveDownModule;
    setModules(copy);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {modules.map((module, index) => (
        <Box key={index}>
          <ModuleMoveable
            key={module}
            moveUp={index > 0 ? () => moveUp(index) : undefined}
            moveDown={
              index < modules.length - 1 ? () => moveDown(index) : undefined
            }
          >
            <Typography>{module}</Typography>
          </ModuleMoveable>
          {index < modules.length - 1 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ArrowDownwardIcon
                fontSize="large"
                sx={{ color: theme.palette.primary.gray }}
              />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
