import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { QuestionFooter } from "./QuestionFooter";
import { reading } from "./widgetTypes";
import { Widget } from "./Widget";

/**
 * Action component is responsible for handling the actions of a step in a task.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.action - The configuration of the action.
 * @param {boolean} props.isComplete - A boolean indicating if the action is complete.
 * @param {function} props.onComplete - A function to be called when the action is complete.
 * @param {function} props.onNext - A function to be called to move to the next action.
 * @param {boolean} props.backEnabled - A boolean indicating if the back button is enabled.
 * @param {function} props.onBack - A function to be called to move to the previous action.
 */
export const Action = ({
  action,
  isComplete,
  onComplete,
  onNext,
  backEnabled,
  onBack,
}) => {
  const widgets = action.widgets;

  const [actionState, setActionState] = useState(0);
  const [activeWidgetIndex, setActiveWidgetIndex] = useState(
    isComplete ? widgets.length - 1 : 0
  );
  const [widgetStates, setWidgetStates] = useState(
    isComplete
      ? widgets.map((widget) => widget.answerState)
      : widgets.map((widget) => widget.defaultState)
  );

  const activeWidget = widgets[activeWidgetIndex];
  const activeState = widgetStates[activeWidgetIndex];
  const activeComplete =
    JSON.stringify(widgetStates[activeWidgetIndex]) ===
    JSON.stringify(activeWidget.answerState);

  const setWidgetState = (index, state) => {
    setWidgetStates((prev) => {
      const newStates = [...prev];
      newStates[index] = state;
      return newStates;
    });
  };

  const nextWidget = () => {
    if (activeWidgetIndex < widgets.length - 1)
      setActiveWidgetIndex(activeWidgetIndex + 1);
    else onComplete();
  };

  const submit = () => {
    if (isComplete) {
      onNext();
      return;
    }

    if (activeWidget.type === reading) {
      let nextState = activeState + 1;
      if (nextState >= activeWidget.answerState) {
        nextWidget();
      }
      setWidgetState(activeWidgetIndex, activeState + 1);
    } else {
      if (activeComplete) {
        nextWidget();
        setActionState(1);
      } else {
        setActionState(2);
        setTimeout(() => {
          setActionState(0);
        }, 500);
      }
    }
  };

  return (
    <Box
      sx={{
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">{action.name}</Typography>
        {widgets.slice(0, activeWidgetIndex + 1).map((widget, index) => (
          <Widget
            key={`${widget.type}-${index}`}
            widget={widget}
            widgetState={widgetStates[index]}
            setWidgetState={(state) => setWidgetState(index, state)}
          />
        ))}
      </Box>
      <QuestionFooter
        questionState={actionState}
        submitEnabled={true}
        onSubmit={submit}
        submitText={
          isComplete
            ? "Next"
            : activeWidget.submitText
            ? activeWidget.submitText
            : "Submit"
        }
        backEnabled={backEnabled}
        onBack={onBack}
      />
    </Box>
  );
};
