import { useState } from "react";
import { StepHeader } from "./StepHeader";
import { Action } from "../Action/Action";
import { StepStart } from "./StepStart";
import { StepComplete } from "./StepComplete";
import { PageContent } from "../Page/PageContent";

export const Step = ({ step, onComplete, onNext }) => {
  const info = step !== undefined ? step.info : undefined;
  const actions = step !== undefined ? step.actions : undefined;

  const [stepProgress, setStepProgress] = useState(actions.map(() => false));
  const [actionIndex, setActionIndex] = useState(-1);

  const handleCompleteAction = () => {
    let copy = [...stepProgress];
    copy[actionIndex] = true;
    setStepProgress(copy);

    if (copy.every((actionComplete) => actionComplete === true)) {
      onComplete();
    }
  };

  const handleNextAction = () => {
    setActionIndex(actionIndex + 1);
  };

  const handleBack = () => {
    if (actionIndex === 0) return;
    setActionIndex(actionIndex - 1);
  };

  if (actionIndex < 0) {
    return <StepStart stepInfo={info} onStart={handleNextAction} />;
  }

  if (actionIndex >= actions.length) {
    return <StepComplete onNext={onNext} stepIndex={step.number} />;
  }

  return [
    <StepHeader step={step.number} title={info.name} progress={stepProgress} />,
    <PageContent>
      <Action
        key={actionIndex}
        action={actions[actionIndex]}
        onComplete={handleCompleteAction}
        isComplete={stepProgress[actionIndex]}
        onNext={handleNextAction}
        backEnabled={actionIndex > 0}
        onBack={handleBack}
      />
    </PageContent>,
  ];
};
