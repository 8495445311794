import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logo from "../logo-full.svg";
import Button from "@mui/material/Button";
import { setMultipleQueryParams } from "../context/ASAQueryParams";
import { taskRoutes } from "../tasks/tasks";
import { STEP_NUMBER, PARTNER_TASK_REFERENCE } from "../context/ASAQueryParams";

export const MainMenu = () => {
  // App version:
  /*
  0.1.0 = alpha
  0.1.1 = alpha task 1 feature complete
  0.1.12 = alpha task 2 feature complete
  0.1.13 = alpha task 3 feature complete
  0.1.14 = alpha task 4 feature complete
  0.1.15 = alpha task 5 feature complete
  0.2.0 = beta feature complete
  */
  const appVersion = "0.2.0";

  const navigateToTask = (taskReference, stepNumber) => {
    let queryParams = {
      [PARTNER_TASK_REFERENCE]: taskReference,
      [STEP_NUMBER]: stepNumber,
    };
    setMultipleQueryParams(queryParams);
  };

  const tasks = () => {
    let map = {};

    const getTaskName = (taskKey) => {
      let name = taskKey.replace(/-/g, " ");
      name = name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return name;
    };

    Object.entries(taskRoutes).forEach(([key, value]) => {
      map[getTaskName(key)] = key;
    });

    return map;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "200px",
        }}
      >
        <img src={logo} alt="Mastery Coding" />
      </Box>
      <Typography>Main Menu</Typography>
      <Typography>beta v{appVersion}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          margin: "1rem",
        }}
      >
        {Object.entries(tasks()).map(([taskName, taskKey]) => (
          <Button
            variant="contained"
            onClick={() => navigateToTask(taskKey, 1)}
          >
            {taskName}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
