import {
  Binary,
  MultipleChoice,
  Reading,
} from "../components/Action/widgetTypes";
import { Action, Step, Task } from "./taskSetup";

const step1 = Step("One Bit", 5, "", [
  Action("What is a bit?", [
    Reading([
      <>
        Computer use <strong>bits</strong> to represent different types of
        information.
      </>,
      <>
        Whether numbers, text, images, or anything else, deep down it's all
        bits.
      </>,
      <>
        But what exactly is a <strong>bit</strong>?
      </>,
    ]),
  ]),
  Action("What is a bit?", [
    Reading([
      <>A bit is the smallest unit of information in a computer.</>,
      <>
        A bit can either be <strong>1</strong> or <strong>0</strong>
      </>,
      <>Toggle the bit below from 0 to 1.</>,
    ]),
    Binary([0], [1], false),
  ]),
  Action("What is a bit?", [
    Reading([
      <>Bits are used to represent different numbers.</>,
      <>A single bit can only represent how many different numbers?</>,
    ]),
    MultipleChoice(["0", "1", "2"], "2"),
  ]),
  Action("What is a bit?", [
    Reading([<>What is the largest number a single bit can represent?</>]),
    MultipleChoice(["0", "1", "2"], "1"),
  ]),
  Action("What is a bit?", [
    Reading([
      <>
        If we want to count to higher numbers we're going to need to add more
        bits.
      </>,
      <>But we'll save that for the next step!</>,
    ]),
  ]),
]);

const step2 = Step("Two Bits, Three Bits", 5, "", [
  Action("Another Bit", [
    Reading([
      <>In the previous step we learned a little bit about bits.</>,
      <>Bits are used to represent information in computers.</>,
      <>
        Bits can be combined to represent other numbers as well, but we need
        more than one bit if we want to count to any number higher than 1.
      </>,
    ]),
  ]),
  Action("One Bit, Two Bit", [
    Reading([
      <>
        When we have two bits, the left bit represents the number{" "}
        <strong>2</strong> and the right bit represents the nubmer{" "}
        <strong>1</strong>.
      </>,
      <>Then we add them together to get the full number.</>,
      <>
        Which combination of bits below represents the number{" "}
        <strong>2?</strong>
      </>,
    ]),
    Binary([0, 0], [1, 0]),
  ]),
  Action("One Bit, Two Bit", [
    Reading([
      <>
        Which combination of bits below represents the number{" "}
        <strong>3?</strong>
      </>,
    ]),
    Binary([0, 0], [1, 1]),
  ]),
  Action("One Bit, Two Bit, Three Bit", [
    Reading([
      <>Once again we've run out of bits so we'll have to add another one.</>,
      <>
        Which combination of bits below represents the number{" "}
        <strong>4?</strong>
      </>,
    ]),
    Binary([0, 0, 0], [1, 0, 0]),
  ]),
  Action("One Bit, Two Bit, Three Bit", [
    Reading([
      <>
        Which combination of bits below represents the number{" "}
        <strong>5?</strong>
      </>,
    ]),
    Binary([0, 0, 0], [1, 0, 1]),
  ]),
  Action("One Bit, Two Bit, Three Bit", [
    Reading([<>What's the largest number we can represent with 3 bits?</>]),
    MultipleChoice(["5", "6", "7"], "7"),
  ]),
]);

const step3 = Step("Half a Byte", 5, "", [
  Action("Four Bits", [
    Reading([
      <>
        In the last step we used <strong>three</strong> bits to represent
        numbers up to <strong>7</strong>
      </>,
      <>If we add another bit we can represent even larger numbers.</>,
    ]),
  ]),
  Action("Four Bits", [
    Reading([
      <>
        Which combination of bits represents the number <strong>8?</strong>
      </>,
    ]),
    Binary([0, 0, 0, 0], [1, 0, 0, 0]),
  ]),
  Action("Four Bits", [
    Reading([
      <>
        Which combination of bits represents the number <strong>9?</strong>
      </>,
    ]),
    Binary([0, 0, 0, 0], [1, 0, 0, 1]),
  ]),
  Action("Four Bits", [
    Reading([
      <>
        Which combination of bits represents the number <strong>10?</strong>
      </>,
    ]),
    Binary([0, 0, 0, 0], [1, 0, 1, 0]),
  ]),
  Action("Four Bits", [
    Reading([
      <>
        Which combination of bits represents the number <strong>15?</strong>
      </>,
    ]),
    Binary([0, 0, 0, 0], [1, 1, 1, 1]),
  ]),
  Action("Four Bits", [
    Reading([<>What is the largest number we can represent with four bits?</>]),
    MultipleChoice(["10", "15", "16"], "15"),
  ]),
]);

export const bitsAndBytes = Task("Bits and Bytes", "", [step1, step2, step3]);
