import { Box } from "@mui/material";
/**
 * LogicGate is a functional component that wraps its children with a Box component.
 * It is used to create a flex container for logic gate components.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {JSX.Element} props.children - The child components to be wrapped.
 * @returns {JSX.Element} A Box component wrapping the child components.
 */
export const LogicGate = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
