import React from "react";
import Box from "@mui/material/Box";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useTheme } from "@emotion/react";

/**
 * ContentSuccess component is responsible for displaying the success content.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {JSX.Element} props.children - The child elements to be rendered within the component.
 * @returns {JSX.Element} The ContentSuccess component.
 */
export const ContentSuccess = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: "16px",
        backgroundColor: theme.palette.primary.light,
        padding: "0.5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <CheckCircleRoundedIcon
        style={{ color: theme.palette.primary.main, fontSize: "2rem" }}
      />
      {children}
    </Box>
  );
};
