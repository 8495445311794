import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ToggleButton } from "./ToggleButton";

const OneInputHeader = () => {
  return [
    <TableCell colSpan={6} align="center">
      A
    </TableCell>,
    <TableCell colSpan={6} align="center">
      O
    </TableCell>,
  ];
};

const TwoInputHeader = () => {
  return [
    <TableCell colSpan={3} align="center">
      A
    </TableCell>,
    <TableCell colSpan={3} align="center">
      B
    </TableCell>,
    <TableCell colSpan={6} align="center">
      O
    </TableCell>,
  ];
};

const tableHeader = [OneInputHeader, TwoInputHeader];

export const TruthTableWidget = ({ widget, widgetState, setWidgetState }) => {
  const inputCount = widgetState[0].length - 1;

  const toggleCellValue = (row, col) => {
    let copy = [...widgetState];
    copy[row][col] = copy[row][col] ? 0 : 1;
    setWidgetState(copy);
  };

  const TableCellContent = (row, col) => {
    if (col === inputCount) {
      return (
        <ToggleButton
          onClick={() => toggleCellValue(row, col)}
          value={widgetState[row][col]}
        />
      );
    }
    return widgetState[row][col];
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>{tableHeader[inputCount - 1]()}</TableRow>
        </TableHead>
        <TableBody>
          {widgetState.map((value, row) => (
            <TableRow key={row}>
              {value.map((cell, col) => (
                <TableCell
                  key={col}
                  colSpan={col < value.length - 1 ? 6 / inputCount : 6}
                >
                  {TableCellContent(row, col)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
