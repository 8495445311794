import React from "react";
import { Box } from "@mui/material";

/**
 * ContentActivity component is responsible for displaying the activity content.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {JSX.Element} props.children - The child elements to be rendered within the component.
 * @returns {JSX.Element} The ContentActivity component.
 */
export const ContentWidget = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};
