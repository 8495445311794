import { ContentSuccess } from "../Content/ContentSuccess";
import { ContentWarning } from "../Content/ContentWarning";
import { Box, Typography } from "@mui/material";
import { ButtonContainer } from "../ButtonContainer";
import { CustomButton } from "../CustomButton";
import { Footer } from "../Page/Footer";
import { PageContent } from "../Page/PageContent";
/**
 * QuestionFooter component is responsible for rendering the footer of a question.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.questionState - The current state of the question (1 for correct, 2 for incorrect).
 * @param {Function} props.onBack - Function to handle the "Back" button click.
 * @param {boolean} props.backEnabled - Boolean to determine if the "Back" button is enabled.
 * @param {boolean} props.submitEnabled - Boolean to determine if the "Submit" button is enabled.
 * @param {Function} props.onSubmit - Function to handle the "Submit" button click.
 * @param {string} props.submitText - The text to display on the "Submit" button.
 */
export const QuestionFooter = ({
  questionState,
  onBack,
  backEnabled,
  submitEnabled,
  onSubmit,
  submitText,
}) => {
  return (
    <Footer>
      <PageContent>
        <Box sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
          {questionState === 1 && (
            <ContentSuccess>
              <Typography variant="p_bold">Correct!</Typography>
            </ContentSuccess>
          )}
          {questionState === 2 && (
            <ContentWarning>
              <Typography variant="p_bold">Oops! Try again.</Typography>
            </ContentWarning>
          )}
        </Box>
      </PageContent>
      <PageContent>
        <ButtonContainer>
          <CustomButton
            text={"Back"}
            onClick={onBack}
            enabled={backEnabled}
            color="backButton"
          />
          <CustomButton
            text={submitText}
            onClick={onSubmit}
            enabled={submitEnabled}
            color="primary"
          />
        </ButtonContainer>
      </PageContent>
    </Footer>
  );
};
