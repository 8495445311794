export const Transistor = ({ state, width = 150, height = 150 }) => {
  if (state) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 140 204"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 70C0 31.3401 31.3401 0 70 0V0C108.66 0 140 31.3401 140 70V136C140 138.209 138.209 140 136 140H4C1.79086 140 0 138.209 0 136V70Z"
          fill="#D5D5D5"
        />
        <path
          d="M70 83.3438C82.8924 83.3438 93.3438 72.8924 93.3438 60C93.3438 47.1076 82.8924 36.6562 70 36.6562C57.1076 36.6562 46.6562 47.1076 46.6562 60C46.6562 72.8924 57.1076 83.3438 70 83.3438Z"
          fill="#FFF617"
        />
        <path
          d="M72.5938 21.0938H67.4062V31.4688H72.5938V21.0938Z"
          fill="#FFF617"
        />
        <path
          d="M72.5938 88.5312H67.4062V98.9062H72.5938V88.5312Z"
          fill="#FFF617"
        />
        <path
          d="M108.906 57.4062H98.5312V62.5938H108.906V57.4062Z"
          fill="#FFF617"
        />
        <path
          d="M41.4688 57.4062H31.0938V62.5938H41.4688V57.4062Z"
          fill="#FFF617"
        />
        <path
          d="M92.0065 78.3407L88.3384 82.0088L95.6745 89.3449L99.3426 85.6768L92.0065 78.3407Z"
          fill="#FFF617"
        />
        <path
          d="M44.3243 30.6551L40.6562 34.3232L47.9924 41.6593L51.6605 37.9913L44.3243 30.6551Z"
          fill="#FFF617"
        />
        <path
          d="M47.9909 78.3389L40.6548 85.6751L44.3229 89.3432L51.659 82.007L47.9909 78.3389Z"
          fill="#FFF617"
        />
        <path
          d="M95.6755 30.6558L88.3394 37.992L92.0074 41.66L99.3436 34.3239L95.6755 30.6558Z"
          fill="#FFF617"
        />
        <line
          x1="34"
          y1="140"
          x2="34"
          y2="204"
          stroke="#292929"
          stroke-width="8"
        />
        <line
          x1="107"
          y1="140"
          x2="107"
          y2="204"
          stroke="#292929"
          stroke-width="8"
        />
      </svg>
    );
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 140 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 70C0 31.3401 31.3401 0 70 0V0C108.66 0 140 31.3401 140 70V136C140 138.209 138.209 140 136 140H4C1.79086 140 0 138.209 0 136V70Z"
        fill="#D5D5D5"
      />
      <line
        x1="34"
        y1="140"
        x2="34"
        y2="204"
        stroke="#292929"
        stroke-width="8"
      />
      <line
        x1="107"
        y1="140"
        x2="107"
        y2="204"
        stroke="#292929"
        stroke-width="8"
      />
      <circle cx="71" cy="60" r="27" fill="#8E8E8E" />
    </svg>
  );
};
