import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";

/**
 * ProgressBar component is a reusable component which displays progress
 * of complete / incomplete steps.
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.index - The current index of the progress.
 * @param {number} props.count - The total count of the progress.
 * @param {Array} props.progressArray - optional array which overrides index / count values
 * @returns {JSX.Element}
 */
export const ProgressBar = ({ index, count, progressArray = undefined }) => {
  const theme = useTheme();
  const progress =
    progressArray === undefined
      ? Array.from({ length: count }, (_, i) => i <= index)
      : progressArray;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "0.5em",
        gap: "0.5em",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      {progress.map((value, index) => (
        <Box
          key={index}
          sx={{
            flexGrow: 1,
            height: "100%",
            borderRadius:
              index === 0
                ? "2em 0em 0em 2em"
                : index === progress.length - 1
                ? "0em 2em 2em 0em"
                : "0em 0em 0em 0em",
            backgroundColor: value
              ? theme.palette.primary.main
              : theme.palette.primary.light,
          }}
        />
      ))}
    </Box>
  );
};
