import {
  Box,
  FormControl,
  Grow,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useTheme } from "@emotion/react";

/**
 * ModuleDropdown is a functional component that renders a dropdown menu.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.selection - The current selected option in the dropdown.
 * @param {function} props.setSelection - A function to update the selected option in the dropdown.
 * @param {Array} props.options - An array of options to be displayed in the dropdown.
 * @param {string} [props.label=""] - The label for the dropdown. Defaults to an empty string if not provided.
 */
export const ModuleDropdown = ({
  selection,
  setSelection,
  options,
  label = "",
}) => {
  const theme = useTheme();

  return (
    <Grow in={true} mountOnEnter unmountOnExit>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem",
          background: theme.palette.primary.offWhite,
          borderRadius: "16px",
          width: "100%",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            value={selection}
            onChange={(event) => setSelection(event.target.value)}
            variant="standard"
            labelId="demo-simple-select-label"
            label={label}
            sx={{
              width: "100%",
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Grow>
  );
};
