import { Box, Typography } from "@mui/material";
import { ModuleDropdown } from "../ModuleOrderWidget/ModuleDropdown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ModuleLabel } from "../ModuleOrderWidget/ModuleLabel";
/**
 * LoopWidget is a component that represents a loop widget.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The configuration of the widget.
 * @param {Object} props.widgetState - The current state of the widget.
 * @param {function} props.setWidgetState - A function to update the state of the widget.
 */
export const LoopWidget = ({ widget, widgetState, setWidgetState }) => {
  const options = widget.options;
  const selection = widgetState.condition;
  const loopModule = widgetState.loopModule;

  const setSelection = (selection) => {
    let copy = {
      condition: selection,
      loopModule: loopModule,
    };
    setWidgetState(copy);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box>
        <ModuleDropdown
          selection={selection ? selection : "Select Condition"}
          setSelection={setSelection}
          options={options}
          label="Condition"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>True</Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <ArrowDownwardIcon fontSize="large" />
            </Box>
            <Box>
              <ArrowUpwardIcon fontSize="large" />
            </Box>
          </Box>

          <ModuleLabel>
            <Typography>{loopModule}</Typography>
          </ModuleLabel>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>False</Typography>
          <ArrowDownwardIcon fontSize="large" />
          <ModuleLabel>
            <Typography>Continue</Typography>
          </ModuleLabel>
        </Box>
      </Box>
    </Box>
  );
};
