import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";

/**
 * OptionModal component
 * @param {Object} props - Component properties
 * @param {string} props.instruction - The instruction to be displayed
 * @param {Array} props.options - The options to be displayed
 * @param {Function} props.setOption - The function to be executed on option selection
 * @returns {React.Node} The OptionModal component
 */
export const OptionModal = ({
  instruction = "Select Operation",
  options = ["A", "B", "C"],
  setOption,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: `${50}%`,
        left: `${50}%`,
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.white,
          display: "flex",
          flexDirection: "column",
          border: `1px solid ${theme.palette.primary.lightGray}`,
          borderRadius: "16px",
          padding: "1rem",
          gap: "0.5rem",
          flexGrow: 1,
        }}
      >
        <Typography align="center">{instruction}</Typography>
        {options.map((option) => (
          <Button variant="contained" onClick={() => setOption(option)}>
            {option}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
