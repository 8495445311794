import { Box } from "@mui/material";

/**
 * Footer component that accepts children props and renders them inside a Box component at the foot of the page.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the Footer.
 */
export const Footer = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  );
};
