import { ContentWidget } from "../Content/ContentWidget";
import { GetWidget } from "./widgetTypes";

/**
 * Widget is a functional component that renders a widget based on its type.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The configuration of the widget.
 * @param {Object} props.widgetState - The current state of the widget.
 * @param {function} props.setWidgetState - A function to update the state of the widget.
 */
export const Widget = ({ widget, widgetState, setWidgetState }) => {
  return (
    <ContentWidget>
      {GetWidget(widget.type)({
        widget: widget,
        widgetState: widgetState,
        setWidgetState: setWidgetState,
      })}
    </ContentWidget>
  );
};
