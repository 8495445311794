import { Box, Typography } from "@mui/material";
import notFoundLogo from "../assets/codie_beach 1.png";
import { PageContentSlim } from "../components/Page/PageContentSlim";
import { Footer } from "../components/Page/Footer";
import { CustomButton } from "../components/CustomButton";

export const NotFound = ({ onRoute }) => {
  return (
    <PageContentSlim>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          height: "80vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography variant="h1_blue" align="center">
            Page Not Found
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <img src={notFoundLogo} alt="404 Not Found" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography align="center">
            Looks like the page you're looking for isn't here anymore.
          </Typography>
        </Box>
      </Box>
      <Footer>
        <PageContentSlim>
          <Box sx={{ padding: "1rem" }}>
            <CustomButton
              text="Back to Homepage"
              enabled={true}
              onClick={onRoute}
            />
          </Box>
        </PageContentSlim>
      </Footer>
    </PageContentSlim>
  );
};
