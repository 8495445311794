import { useTheme } from "@emotion/react";
import { Box, Button } from "@mui/material";

/**
 * OperationSelector component
 * @param {Object} props - Component properties
 * @param {number} props.top - The top position of the selector in percentage
 * @param {number} props.left - The left position of the selector in percentage
 * @param {string} props.operation - The operation to be displayed
 * @param {Function} props.onClick - The function to be executed on click
 * @param {boolean} props.disabled - Whether the button is disabled
 * @returns {React.Node} The OperationSelector component
 */
export const OperationSelector = ({
  top,
  left,
  operation,
  onClick,
  disabled = false,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        top: `${top}%`,
        left: `${left}%`,
        transform: "translate(-50%, -50%)",
      }}
    >
      <Button
        disabled={disabled}
        variant="outline"
        onClick={onClick}
        sx={{
          fontSize: "large",
          color: theme.palette.primary.white,
          whiteSpace: "nowrap",
          "&.Mui-disabled": {
            color: theme.palette.primary.lightGray,
          },
        }}
      >
        {operation}
      </Button>
    </Box>
  );
};
