import {
  ModuleOrder,
  MultipleChoice,
  Reading,
} from "../components/Action/widgetTypes";

import { Task, Step, Action } from "./taskSetup";

const step1 = Step("Modules", 5, "", [
  Action("What are Modules?", [
    Reading([
      <>
        <strong>Modules</strong> are self-contained portions of code.
      </>,
      <>
        Modules usually do one specific thing. When a computer executes the code
        in a module it's called <strong>running</strong> the code.
      </>,
      <>
        The order in which modules are run can make a big difference in how a
        program operates.
      </>,
      <>
        The order of modules is called the <strong>Sequence</strong> of a
        program.
      </>,
    ]),
  ]),
  Action("Peanut Butter Sandwich", [
    Reading([
      "Let's practice some module sequencing. Rearrange the modules below to make a peanut butter sandwich.",
    ]),
    ModuleOrder(
      [
        "Eat the sandwhich",
        "Spread the peanut butter",
        "Open the peanut butter jar",
      ],
      [
        "Open the peanut butter jar",
        "Spread the peanut butter",
        "Eat the sandwhich",
      ]
    ),
  ]),
  Action("Baking Cookies", [
    Reading([
      "Maybe instead of a sandwich we want to bake cookies. Select the appropriate module order below.",
    ]),
    ModuleOrder(
      [
        "Scoop the dough onto a baking sheet",
        "Mix the ingredients to make the dough",
        "Put the baking sheet in the oven",
      ],
      [
        "Mix the ingredients to make the dough",
        "Scoop the dough onto a baking sheet",
        "Put the baking sheet in the oven",
      ]
    ),
  ]),
  Action("Calculator Sequence", [
    Reading([
      "Let's say we're building a calculator app that can add two numbers. Select the appropriate module order for our calculator below.",
    ]),
    ModuleOrder(
      [
        "Add two numbers together",
        "Show the answer",
        "Ask the user to input two numbers",
      ],
      [
        "Ask the user to input two numbers",
        "Add two numbers together",
        "Show the answer",
      ]
    ),
  ]),
]);

const step2 = Step("Order of Operations", 5, "", [
  Action("Order of Operations", [
    Reading([
      <>
        Code <strong>sequences</strong> are similar to the{" "}
        <strong>Order of Operations</strong> in math.
      </>,
      <>
        The order in which certain operations are executed can make a big
        difference in the answer to an equation.
      </>,
      <>
        <strong>PEMDAS</strong> stands for:
        <ul>
          <li>Parentheses</li>
          <li>Exponent</li>
          <li>Multiplication</li>
          <li>Division</li>
          <li>Addition</li>
          <li>Subtraction</li>
        </ul>
      </>,
    ]),
  ]),
  Action("Module Math", [
    Reading([
      <>
        Based on the order of operations, order the modules below to match the
        following equation:
        <br />
        <br />
        <strong>2 + 2 x 2</strong>
      </>,
    ]),
    ModuleOrder(["2 + 4", "2 x 2"], ["2 x 2", "2 + 4"]),
  ]),
  Action("Module Math", [
    Reading([
      <>
        What happens to the order of operations if we add some parentheses? Does
        this change the module order?
        <br />
        <br />
        <strong>(2 + 2) x 2</strong>
      </>,
    ]),
    ModuleOrder(["4 x 2", "2 + 2"], ["2 + 2", "4 x 2"]),
  ]),

  Action("Module Math", [
    Reading([
      <>
        Let's try again with three modules.
        <br />
        <br />
        <strong>(10 + 2) / 3 + 1</strong>
      </>,
    ]),

    ModuleOrder(["4 + 1", "10 + 2", "12 / 3"], ["10 + 2", "12 / 3", "4 + 1"]),
  ]),
]);

const step3 = Step("Sequential Control", 5, "", [
  Action("Sequential Control", [
    Reading([
      <>
        So far we've seen how modules can be arranged in a sequence to control
        the outcome of a program.
      </>,
      <>
        Computer scientists have a name for this type of control:{" "}
        <strong>Sequential Control</strong>.
      </>,
      <>
        In Computer Science, <strong>Control Structures</strong> are different
        logical structures used to control the flow or progression of a program.
      </>,
    ]),
  ]),
  Action("Rock Paper Scissors", [
    Reading([
      "Imagine we're programming a game of Rock, Paper, Scisors. What's the first module we would run?",
    ]),
    MultipleChoice(
      ["Get Player Choices", "Compare Player Choices", "Show Winner"],
      "Get Player Choices"
    ),
  ]),
  Action("Rock Paper Scissors", [
    Reading(["After we get the player choices, which module should we run?"]),
    MultipleChoice(
      ["Compare Player Choices", "Show Winner"],
      "Compare Player Choices"
    ),
  ]),
  Action("Control Flow Diagram", [
    Reading([
      <>
        To visualize the flow / sequence of a program, computer scientists often
        use what's called a <strong>Control Flow Diagram</strong>.
      </>,
      <>
        Control Flow Diagrams show which modules the program will run. When one
        module is complete the program executes the next module.
      </>,
      <>These diagrams use arrows to show which module will run next.</>,
    ]),
  ]),
  Action("Rock Paper Scissors", [
    Reading([
      <>
        Rearrange the modules below to create the{" "}
        <strong>Control Flow Diagram</strong> for our Rock Paper Scissors game.
      </>,
    ]),
    ModuleOrder(
      ["Show Winner", "Get Player Choices", "Compare Player Choices"],
      ["Get Player Choices", "Compare Player Choices", "Show Winner"]
    ),
  ]),
]);

export const sequentialControl = Task("Sequential Control", "", [
  step1,
  step2,
  step3,
]);
