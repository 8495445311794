import { Box, Stack, Switch } from "@mui/material";
import { Transistor } from "../ResponsiveGraphics/Transistor";

const graphicElements = {
  transistor: Transistor,
};

/**
 * GraphicSwitchWidget component is responsible for rendering a graphic element and a switch.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The widget object.
 * @param {boolean} props.widgetState - The current state of the widget.
 * @param {Function} props.setWidgetState - Function to update the widget state.
 */
export const GraphicSwitchWidget = ({
  widget,
  widgetState,
  setWidgetState,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {graphicElements[widget.graphic]({ state: widgetState })}
      <Stack direction={"row"} spacing={1} alignItems="center">
        <Switch
          checked={widgetState}
          onChange={() => setWidgetState(!widgetState)}
        ></Switch>
      </Stack>
    </Box>
  );
};
