import { Box, Typography } from "@mui/material";
import React from "react";
import taskIcon from "../../assets/default_task_icon.png";
import { CustomButton } from "../CustomButton";
import { useTheme } from "@emotion/react";
import { Footer } from "../Page/Footer";
import { PageContentSlim } from "../Page/PageContentSlim";

/**
 * StepComplete component is responsible for displaying a completion message and image for a step.
 *
 * @param {string} stepName - The name of the completed step.
 * @param {Function} onNext - Function to be called when the "Next Step" button is clicked.
 * @returns {JSX.Element} - The rendered StepComplete component.
 */
export const TaskComplete = ({ stepName, onNext }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0rem 1.5rem",
        justifyContent: "center",
        alignItems: "flex-start",
        alignSelf: "stretch",
        flex: "1 0 0",
        height: "95vh",
        background: theme.palette.primary.white,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: ".5rem",
          flex: "1 0 0 ",
          alignSelf: "stretch",
        }}
      >
        <Typography variant="h2">Task Complete!</Typography>
        <img src={taskIcon} alt="Task Icon" />

        <Footer>
          <PageContentSlim>
            <Box sx={{ padding: "1rem" }}>
              <CustomButton
                enabled={true}
                text={"Main Menu"}
                color="primary"
                onClick={() => (window.location.href = "/")}
              />
            </Box>
          </PageContentSlim>
        </Footer>
      </Box>
    </Box>
  );
};
