import { useTheme } from "@mui/material/styles";

/**
 * And component
 * @param {Object} props - The properties passed to the component
 * @param {boolean} props.inputA - The state of the first input of the And gate
 * @param {boolean} props.inputB - The state of the second input of the And gate
 */
export const And = ({ inputA, inputB }) => {
  const theme = useTheme();

  // Define the colors for the on and off states
  const onFillColor = theme.palette.logicGate.onFillColor;
  const offFillColor = theme.palette.logicGate.offFillColor;
  const onStrokeColor = theme.palette.logicGate.onStrokeColor;
  const offStrokeColor = theme.palette.logicGate.offStrokeColor;

  // Determine the fill and stroke colors for the first input based on its state
  const fillA = inputA ? onFillColor : offFillColor;
  const strokeA = inputA ? onStrokeColor : offStrokeColor;

  // Determine the fill and stroke colors for the second input based on its state
  const fillB = inputB ? onFillColor : offFillColor;
  const strokeB = inputB ? onStrokeColor : offStrokeColor;

  // Determine the fill and stroke colors for the output based on the state of both inputs
  const fillO = inputA && inputB ? onFillColor : offFillColor;
  const strokeO = inputA && inputB ? onStrokeColor : offStrokeColor;

  return (
    <svg
      width="291"
      height="100"
      viewBox="0 0 291 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="6"
        width="36"
        height="36"
        rx="14"
        stroke={strokeA}
        stroke-width="4"
        fill={fillA}
      />
      <rect
        x="2"
        y="58"
        width="36"
        height="36"
        rx="14"
        stroke={strokeB}
        stroke-width="4"
        fill={fillB}
      />
      <path d="M40 24H93" stroke={strokeA} stroke-width="4" />
      <path d="M40 76H93" stroke={strokeB} stroke-width="4" />
      <path d="M198 50H251" stroke={strokeO} stroke-width="4" />
      <rect
        x="253"
        y="32"
        width="36"
        height="36"
        rx="14"
        stroke={strokeO}
        stroke-width="4"
        fill={fillO}
      />
      <path
        d="M95 4C95 2.89543 95.8954 2 97 2H151C177.51 2 199 23.4903 199 50C199 76.5097 177.51 98 151 98H97C95.8954 98 95 97.1046 95 96V4Z"
        stroke={strokeO}
        stroke-width="4"
        fill={fillO}
      />
      <defs>
        <clipPath id="clip0_189_489">
          <rect y="4" width="40" height="40" rx="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_189_489">
          <rect y="56" width="40" height="40" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
