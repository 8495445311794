import { useTheme } from "@mui/material/styles";
/**
 * Buffer component
 * @param {Object} props - The properties passed to the component
 * @param {boolean} props.input - The state of the input of the Buffer gate
 * @param {string} props.width - The width of the SVG
 * @param {string} props.height - The height of the SVG
 */
export const Buffer = ({ input = false, width = "100%", height = "100%" }) => {
  const theme = useTheme();
  // Define the colors for the on and off states
  const onFillColor = theme.palette.logicGate.onFillColor;
  const offFillColor = theme.palette.logicGate.offFillColor;
  const onStrokeColor = theme.palette.logicGate.onStrokeColor;
  const offStrokeColor = theme.palette.logicGate.offStrokeColor;

  // Determine the fill and stroke colors based on the state of the input
  let fillColor = input ? onFillColor : offFillColor;
  let strokeColor = input ? onStrokeColor : offStrokeColor;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 291 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_226_318)">
        <path
          d="M19.8457 45.3916L16.3652 56H13.5615L18.3164 43.2031H20.1006L19.8457 45.3916ZM22.7373 56L19.248 45.3916L18.9668 43.2031H20.7686L25.5498 56H22.7373ZM22.5791 51.2363V53.3018H15.8203V51.2363H22.5791Z"
          fill="#292929"
        />
      </g>
      <rect
        x="2"
        y="32"
        width="36"
        height="36"
        rx="14"
        stroke={strokeColor}
        strokeWidth="4"
        fill={fillColor}
      />
      <path d="M40 50H93" stroke={strokeColor} stroke-width="4" />
      <path d="M198 50L251 50" stroke={strokeColor} stroke-width="4" />
      <rect
        x="253"
        y="32"
        width="36"
        height="36"
        rx="14"
        stroke={strokeColor}
        strokeWidth="4"
        fill={fillColor}
      />
      <path
        d="M197.781 51.8174L98.1877 97.7804C96.6018 98.5124 95 97.3435 95 95.963L95 4.03699C95 2.6565 96.6018 1.48764 98.1877 2.21958L197.781 48.1826C199.406 48.9327 199.406 51.0673 197.781 51.8174Z"
        stroke={strokeColor}
        strokeWidth="4"
        fill={fillColor}
      />
      <defs>
        <clipPath id="clip0_226_318">
          <rect y="30" width="40" height="40" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
