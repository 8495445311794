import { Box } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ReadBubble } from "./ReadBubble";

/**
 * ReadingWidget is a functional component that renders a reading widget.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The configuration of the widget.
 * @param {Object} props.widgetState - The current state of the widget.
 * @param {function} props.setWidgetState - A function to update the state of the widget.
 */
export const ReadingWidget = ({ widget, widgetState, setWidgetState }) => {
  const textIndex = widgetState;
  const content = widget.content;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "1rem",
      }}
    >
      {content.slice(0, textIndex + 1).map((text, index) => (
        <ReadBubble key={index} content={text} />
      ))}
      {widgetState < widget.answerState && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <MoreHorizIcon />
        </Box>
      )}
    </Box>
  );
};
