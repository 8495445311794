import React from "react";
import { Box } from "@mui/material";

/**
 * ButtonContainer component is a reusable component which wraps its children
 * with a styled Box component from @mui/material.
 * @param {Object} props - The properties passed to the component.
 * @param {JSX.Element[]} props.children - The child elements to be wrapped.
 * @returns {JSX.Element}
 */
export const ButtonContainer = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      {children}
    </Box>
  );
};
