import {
  PARTNER_TASK_REFERENCE,
  UrlParamsProvider,
  clearQueryParams,
  setQueryParam,
} from "./context/ASAQueryParams";
import { ASAParamsContext } from "./context/ASAQueryParams";
import { MainMenu } from "./pages/MainMenu";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import React, { useContext } from "react";
import { CssBaseline } from "@mui/material";
import { Task } from "./components/Task/Task";
import { Homepage } from "./pages/Homepage";
import { taskRoutes } from "./tasks/tasks";
import { NotFound } from "./pages/NotFound";

/**
 * Route to component based on ASA parameters
 * If no partner task reference is set route to main menu / landing page
 * If partner task reference is set route to task.
 * @returns
 */
const RoutingWithParams = () => {
  const { partnerTaskReference } = useContext(ASAParamsContext);

  if (!partnerTaskReference) {
    return (
      <Homepage onRoute={() => setQueryParam(PARTNER_TASK_REFERENCE, "?")} />
    );
  }

  if (partnerTaskReference === "?") return <MainMenu />;

  if (!taskRoutes.hasOwnProperty(partnerTaskReference)) {
    return <NotFound onRoute={clearQueryParams} />;
  }

  return <Task />;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UrlParamsProvider>
        <RoutingWithParams />
      </UrlParamsProvider>
    </ThemeProvider>
  );
};

export default App;
