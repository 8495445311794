import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";

export const Label = ({ text }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        padding: "0.5rem 1rem",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        borderRadius: "1rem",
        background: theme.palette.primary.light,
      }}
    >
      <Typography variant="h3_blue">{text}</Typography>
    </Box>
  );
};
