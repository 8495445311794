import { Box } from "@mui/material";
import { LabeledButton } from "./LabeledButton";

/**
 * BinaryWidget component is responsible for handling the binary widget state.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.widget - The widget object.
 * @param {Array} props.widgetState - The current state of the widget.
 * @param {Function} props.setWidgetState - Function to update the widget state.
 */
export const BinaryWidget = ({ widget, widgetState, setWidgetState }) => {
  const getBitLabel = (numBits, index) => {
    let bitIndex = numBits - (index + 1);
    return (2 ** bitIndex).toString();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
      {widgetState.map((value, index) => (
        <LabeledButton
          key={index}
          onChange={() => {
            let newWidgetState = [...widgetState];
            newWidgetState[index] = value === 0 ? 1 : 0;
            setWidgetState(newWidgetState);
          }}
          value={value}
          label={
            widget.labelBits
              ? getBitLabel(widgetState.length, index)
              : undefined
          }
        />
      ))}
    </Box>
  );
};
