import { sequentialControl } from "./sequentialControl";
import { selectionControl } from "./selectionControl";
import { iterationControl } from "./iterationControl";
import { logicGates } from "./logicGates";
import { bitsAndBytes } from "./bitsandbytes";

export const taskRoutes = {
  "sequential-control": sequentialControl,
  "selection-control": selectionControl,
  "iteration-control": iterationControl,
  "logic-gates": logicGates,
  "bits-and-bytes": bitsAndBytes,
};
