import { Button, Typography } from "@mui/material";

/**
 * CustomButton is a reusable button component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.enabled - If true, the button is enabled. If false, the button is disabled.
 * @param {string} props.text - The text displayed on the button.
 * @param {string} props.color - The color of the button.
 * @param {function} props.onClick - The function to execute when the button is clicked.
 * @returns {JSX.Element} A Button component from @mui/material.
 */
export const CustomButton = ({ enabled, text, color, onClick }) => {
  return (
    <Button
      disabled={!enabled}
      color={color}
      onClick={onClick}
      textTransform="none"
    >
      <Typography variant="p_bold">{text}</Typography>
    </Button>
  );
};
