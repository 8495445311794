import { useTheme } from "@emotion/react";

/**
 * SequenceDiagramSVG component
 * @param {Object} props - The properties that define the component's behavior and display.
 * @param {number} props.moduleWidth - The width of the module in the sequence diagram.
 * @param {Array<boolean>} props.selectModules - An array of booleans that determine whether each module part of the sequence diagram is selected.
 * @returns {JSX.Element} The SVG representation of a sequence diagram.
 */
export const SequenceDiagramSVG = ({
  moduleWidth = 128,
  selectModules = [false, false, false],
}) => {
  const theme = useTheme();
  return (
    <svg
      width="327"
      height="304"
      viewBox="0 0 327 304"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="99.5"
        width={moduleWidth}
        height="64"
        rx="4"
        fill={
          selectModules[0]
            ? theme.palette.primary.main
            : theme.palette.primary.lightGray
        }
      />

      {selectModules.length > 1 && (
        <>
          <path
            d="M165.5 72C165.5 70.8954 164.605 70 163.5 70C162.395 70 161.5 70.8954 161.5 72L165.5 72ZM163.5 112L175.047 92L151.953 92L163.5 112ZM161.5 72L161.5 94L165.5 94L165.5 72L161.5 72Z"
            fill={theme.palette.primary.black}
          />
          <rect
            x="99.5"
            y="120"
            width={moduleWidth}
            height="64"
            rx="4"
            fill={
              selectModules[1]
                ? theme.palette.primary.main
                : theme.palette.primary.lightGray
            }
          />
        </>
      )}

      {selectModules.length > 2 && (
        <>
          <path
            d="M165.5 192C165.5 190.895 164.605 190 163.5 190C162.395 190 161.5 190.895 161.5 192L165.5 192ZM163.5 232L175.047 212L151.953 212L163.5 232ZM161.5 192L161.5 214L165.5 214L165.5 192L161.5 192Z"
            fill={theme.palette.primary.black}
          />
          <rect
            x="99.5"
            y="240"
            width={moduleWidth}
            height="64"
            rx="4"
            fill={
              selectModules[2]
                ? theme.palette.primary.main
                : theme.palette.primary.lightGray
            }
          />
        </>
      )}
    </svg>
  );
};
