import { Button } from "@mui/material";

/**
 * ToggleButton is a functional component that renders a button with toggle functionality.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.value - The current value of the toggle button. Typically 0 or 1.
 * @param {function} props.onClick - The function to be executed when the button is clicked.
 */
export const ToggleButton = ({ value, onClick }) => {
  const handleClick = (event) => {
    console.log("handle click");
    onClick(event);
  };

  return (
    <Button
      onClick={handleClick}
      color={value === 0 ? "toggleOff" : "toggleOn"}
    >
      {value}
    </Button>
  );
};
