import { Box, Typography } from "@mui/material";

/**
 * DiagramLabel component
 * @param {Object} props - Component properties
 * @param {number} props.top - The top position of the label in percentage
 * @param {number} props.left - The left position of the label in percentage
 * @param {string} props.text - The text to be displayed in the label
 * @returns {React.Node} The DiagramLabel component
 */
export const DiagramLabel = ({ top, left, text }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: `${top}%`,
        left: `${left}%`,
        transform: "translate(-50%, -50%)",
      }}
    >
      <Typography
        variant="h2"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
