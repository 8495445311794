import { Box, Typography } from "@mui/material";
import { ProgressBar } from "../ProgressBar";
import { Header } from "../Page/Header";

/**
 * StepHeader component is a reusable component for displaying the header of a step.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - Child components
 * @returns {JSX.Element} A Box component with specific styling.
 */
export const StepHeader = ({ step, title, progress }) => {
  return (
    <Header>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="0.5rem"
      >
        <Typography variant="h3_dark_blue">Step {step}</Typography>
        <Typography variant="h3" align={"center"}>
          {title}
        </Typography>
      </Box>
      <ProgressBar progressArray={progress} />
    </Header>
  );
};
