import { Box, Button, Grow } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@emotion/react";

/**
 * ModuleMoveable is a functional component that renders a moveable module.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The child elements to be rendered within the module.
 * @param {function} [props.moveUp] - A function to move the module up. Defaults to undefined if not provided.
 * @param {function} [props.moveDown] - A function to move the module down. Defaults to undefined if not provided.
 */
export const ModuleMoveable = ({
  children,
  moveUp = undefined,
  moveDown = undefined,
}) => {
  const theme = useTheme();
  return (
    <Grow in={true} mountOnEnter unmountOnExit>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem",
          background: theme.palette.primary.offWhite,
          borderRadius: "16px",
        }}
      >
        <Box
          sx={{
            flex: 12,
            display: "flex",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
          }}
        >
          <Button
            onClick={moveDown ? moveDown : undefined}
            variant="outline"
            disabled={!moveDown}
            sx={{
              maxWidth: "1rem",
            }}
          >
            <KeyboardArrowDownIcon
              color={moveDown ? "primary" : "lightGray"}
              fontSize="large"
            />
          </Button>
          <Button
            onClick={moveUp ? moveUp : undefined}
            variant="outline"
            disabled={!moveUp}
            sx={{
              maxWidth: "1rem",
            }}
          >
            <KeyboardArrowUpIcon
              color={moveUp ? "primary" : "lightGray"}
              fontSize="large"
            />
          </Button>
        </Box>
      </Box>
    </Grow>
  );
};
