import { Box, Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
import { And } from "../LogicGatesSVGs/And";
import { LogicGate } from "./LogicGate";
import { useTheme } from "@emotion/react";

/**
 * AndGate component is responsible for managing the state of the inputs and the output of the AND gate.
 * @param {Object} props - The props object
 * @param {Function} props.setAnswer - The function to set the answer of the AND gate
 * @param {boolean} props.disabled - The state of the gate, true if the gate is disabled, false otherwise
 * @param {Array} props.inputOverride - An array of boolean values to override the default input values
 */
export const AndGate = ({ input, toggleInput, disabled = false }) => {
  const theme = useTheme();
  return (
    <LogicGate>
      <And inputA={input[0]} inputB={input[1]} />
      <Box
        sx={{
          position: "absolute",
          top: `${23}%`,
          left: "20px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Checkbox
          disabled={disabled}
          color="primary"
          aria-label="toggle"
          onClick={() => toggleInput(0)}
          icon={<Typography variant="h3">A</Typography>}
          checkedIcon={
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.white }}
            >
              A
            </Typography>
          }
          checked={input[0]}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: `${75}%`,
          left: "20px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Checkbox
          disabled={disabled}
          color="primary"
          aria-label="toggle"
          onClick={() => toggleInput(1)}
          icon={<Typography variant="h3">B</Typography>}
          checkedIcon={
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.white }}
            >
              B
            </Typography>
          }
          checked={input[1]}
        />
      </Box>
    </LogicGate>
  );
};
