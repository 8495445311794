import React, { createContext, useEffect, useState } from "react";

export const USER_ID = "user_id";
export const UNIQUE_TASK_KEY = "unique_task_key";
export const STEP_NUMBER = "step_number";
export const PARTNER_TASK_REFERENCE = "partner_task_reference";

/**
 * Custom hook to parse the query parameters from the current URL.
 * @returns An object containing the query parameters.
 */
function useQueryParams() {
  const [search, setSearch] = useState(window.location.search);

  useEffect(() => {
    const handleLocationChange = () => {
      setSearch(window.location.search);
    };

    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  return new URLSearchParams(search);
}

/**
 * Utility function to set the user_id in the query parameters.
 * @param {string} userID - The user ID to set.
 */
export function setUserID(userID) {
  setQueryParam(USER_ID, userID);
}

/**
 * Utility function to set the unique_task_key in the query parameters.
 * @param {string} uniqueTaskKey - The unique task key to set.
 */
export function setTaskKey(uniqueTaskKey) {
  setQueryParam(UNIQUE_TASK_KEY, uniqueTaskKey);
}

/**
 * Utility function to set the step_number in the query parameters.
 * @param {string} stepNumber - The step number to set.
 */
export function setStepNumber(stepNumber) {
  setQueryParam(STEP_NUMBER, stepNumber);
}

/**
 * Utility function to set the partner_task_reference in the query parameters.
 * @param {string} partnerTaskReference - The partner task reference to set.
 */
export function setPartnerTaskReference(partnerTaskReference) {
  setQueryParam(PARTNER_TASK_REFERENCE, partnerTaskReference);
}

/**
 * Function to set a query parameter in the current URL.
 * @param {string} parameter - The name of the query parameter to set.
 * @param {string} value - The value to set for the query parameter.
 */
export function setQueryParam(parameter, value) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(parameter, value);

  window.history.pushState(null, "", "?" + queryParams.toString());
  window.dispatchEvent(new PopStateEvent("popstate"));
}

/**
 * Set multiple query parameters simultaneously
 * @param {dictionary} parameterValues
 */
export function setMultipleQueryParams(parameterValues) {
  const queryParams = new URLSearchParams(window.location.search);
  for (let key in parameterValues) {
    queryParams.set(key, parameterValues[key]);
  }

  window.history.pushState(null, "", "?" + queryParams.toString());
  window.dispatchEvent(new PopStateEvent("popstate"));
}

/**
 * Route to homepage and clear query parameters
 */
export function clearQueryParams() {
  window.history.pushState(null, "", window.location.pathname);
  window.dispatchEvent(new PopStateEvent("popstate"));
}

export const ASAParamsContext = createContext();

export const UrlParamsProvider = ({ children }) => {
  const queryParams = useQueryParams();
  const userId = queryParams.get(USER_ID);
  const uniqueTaskKey = queryParams.get(UNIQUE_TASK_KEY);
  const step = queryParams.get(STEP_NUMBER);
  const partnerTaskReference = queryParams.get(PARTNER_TASK_REFERENCE);

  return (
    <ASAParamsContext.Provider
      value={{ userId, uniqueTaskKey, step, partnerTaskReference }}
    >
      {children}
    </ASAParamsContext.Provider>
  );
};
