import { Box, Grow, Typography } from "@mui/material";

/**
 * ReadBubble is a functional component that renders a reading bubble with the provided content.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.content - The content to be displayed in the reading bubble.
 */
export const ReadBubble = ({ content }) => {
  return (
    <Grow in={true}>
      <Box width={"100%"}>
        <Typography variant="p">{content}</Typography>
      </Box>
    </Grow>
  );
};
