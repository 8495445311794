import { createTheme } from "@mui/material/styles";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";

const blue = "#1272E0";
const lightBlue = "#E8F1FC";
const fadeBlue = "#6999D0";
const darkBlue = "#003A7C";
const white = "#FAFAFA";
const offWhite = "#F2F2F2";
const lightGray = "#CED1D6";
const gray = "#8B8E94";
const black = "#292929";
const yellow = "#FFE999";
const lightYellow = "#FFF6D6";
const darkYellow = "#FF9416";

const MCTheme = {
  palette: {
    primary: {
      main: blue,
      light: lightBlue,
      fade: fadeBlue,
      dark: darkBlue,
      constrastText: black,
      white: white,
      offWhite: offWhite,
      lightGray: lightGray,
      gray: gray,
      black: black,
      yellow: yellow,
      lightYellow: lightYellow,
      darkYellow: darkYellow,
    },
    background: {
      default: white,
    },
    secondary: {
      main: offWhite,
      light: lightGray,
      dark: gray,
      constrastText: black,
    },
    backButton: {
      main: lightGray,
      contrastText: black,
    },
    toggleOff: {
      main: lightBlue,
      contrastText: blue,
      background: white,
    },
    toggleOn: {
      main: blue,
      contrastText: white,
      background: blue,
    },
    logicGate: {
      onFillColor: blue,
      offFillColor: white,
      onStrokeColor: blue,
      offStrokeColor: lightGray,
    },
    truthTable: {
      header: lightBlue,
      border: lightGray,
    },
  },
  typography: {
    fontFamily: "Rubik",
    h1_blue: {
      fontFamily: "Rubik",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      color: blue,
    },
    h2: {
      fontFamily: "Rubik",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    },
    h2_blue: {
      fontFamily: "Rubik",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      color: blue,
    },
    h3_blue: {
      fontFamily: "Rubik",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      color: blue,
    },
    h3_dark_blue: {
      fontFamily: "Rubik",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      color: darkBlue,
    },
    h3: {
      fontFamily: "Rubik",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      color: black,
    },
    p_bold: {
      fontFamily: "Rubik",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "140%",
    },
    p: {
      fontFamily: "Rubik",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "140%",
    },
    p_centered: {
      fontFamily: "Rubik",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "140%",
      align: "center",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
        variant: "contained",
        style: {
          borderRadius: "16px",
          height: "48px",
          flexGrow: 1,
          width: "100%",
          textTransform: "none",
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: "primary",
        disableRipple: true,
      },
    },
  },
};

export default createTheme(MCTheme);
