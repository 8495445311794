import React from "react";
import {
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";

/**
 * MultipleChoiceWidget component for rendering a multiple choice widget.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string[]} props.options - An array of options for the multiple choice.
 * @param {function} props.handleAnswer - A function to handle the selected answer.
 * @param {boolean} [props.disabled=false] - A boolean to determine if the widget is disabled.
 * @returns {JSX.Element} The rendered MultipleChoiceWidget component.
 */
export const MultipleChoiceWidget = ({
  widget,
  widgetState,
  setWidgetState,
}) => {
  const theme = useTheme();
  const options = widget.options;
  const value = widgetState;
  const onChange = (_, a) => {
    setWidgetState(a);
  };

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: "75%",
          md: "50%",
        },
        display: "flex",
        paddingLeft: "2rem",
      }}
    >
      <FormControl
        sx={{
          flexGrow: 1,
        }}
      >
        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={onChange}
          sx={{
            gap: "1rem",
          }}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option}
              control={<Radio sx={{ display: "none" }} />}
              label={<Typography>{option}</Typography>}
              sx={{
                backgroundColor:
                  value === option
                    ? theme.palette.primary.light
                    : theme.palette.primary.white,
                borderRadius: "16px",
                border:
                  value === option
                    ? `0.125rem solid ${theme.palette.primary.main}`
                    : `0.125rem solid ${theme.palette.primary.lightGray}`,
                padding: "0.25rem",
                justifyContent: "center",
                minHeight: "3rem",
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
