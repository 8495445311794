import { useTheme } from "@emotion/react";

export const ConditionDiagramSVG = () => {
  const theme = useTheme();
  return (
    <svg
      width="328"
      height="288"
      viewBox="0 0 328 288"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M162.891 84.7396C163.562 84.2917 164.438 84.2917 165.109 84.7396L251.504 142.336C252.691 143.128 252.691 144.872 251.504 145.664L165.109 203.26C164.438 203.708 163.562 203.708 162.891 203.26L76.4961 145.664C75.3087 144.872 75.3087 143.128 76.4962 142.336L162.891 84.7396Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
