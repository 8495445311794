import React from "react";
import { Box, Typography } from "@mui/material";
import taskIcon from "../../assets/default_task_icon.png";
import { Label } from "../Labels/Label";
import { CustomButton } from "../CustomButton";
import { Footer } from "../Page/Footer";
import { PageContentSlim } from "../Page/PageContentSlim";

/**
 * Step Start component is responsible for displaying the task start screen.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the task.
 * @param {string} props.description - A brief description of the task.
 * @param {function} props.onNext - sets the task step
 * @returns {JSX.Element} The TaskStart component.
 */
export const StepStart = ({ stepInfo, onStart }) => {
  const name = stepInfo.name;
  const estimatedTime = stepInfo.estimatedTime;
  const description = stepInfo.description;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0rem 1.5rem",
        justifyContent: "center",
        alignItems: "flex-start",
        alignSelf: "stretch",
        flex: "1 0 0",
        height: "95vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: ".5rem",
          flex: "1 0 0 ",
          alignSelf: "stretch",
        }}
      >
        <Typography variant="h2">{name}</Typography>
        <img alt={name} src={taskIcon} />
        <Label text={`Estimated Time: ${estimatedTime} minutes`} />
        <Box sx={{ maxWidth: "35rem" }}>
          <Typography variant="p_centered">{description}</Typography>
        </Box>
        <Footer>
          <PageContentSlim>
            <Box sx={{ padding: "1rem" }}>
              <CustomButton
                enabled={true}
                text={"Get Started"}
                color="primary"
                onClick={onStart}
              />
            </Box>
          </PageContentSlim>
        </Footer>
      </Box>
    </Box>
  );
};
