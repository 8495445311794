import { Box, Typography } from "@mui/material";
import { ToggleButton } from "./ToggleButton";

/**
 * LabeledButton component is responsible for rendering a button with an optional label.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onChange - Function to be called when the button is clicked.
 * @param {string} props.value - The text to be displayed on the button.
 * @param {string} [props.label] - The text to be displayed as the label. If not provided, no label will be displayed.
 */
export const LabeledButton = ({ onChange, value, label = undefined }) => {
  if (label) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography>{label}</Typography>
        <ToggleButton value={value} onClick={onChange} />
      </Box>
    );
  }

  return <ToggleButton value={value} onClick={onChange} />;
};
