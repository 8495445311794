import React from "react";
import Box from "@mui/material/Box";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { useTheme } from "@emotion/react";

/**
 * ContentWarning component is responsible for displaying the warning content.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {JSX.Element} props.children - The child elements to be rendered within the component.
 * @returns {JSX.Element} The ContentWarning component.
 */
export const ContentWarning = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: "16px",
        backgroundColor: theme.palette.primary.lightYellow,
        padding: "0.5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <ErrorRoundedIcon
        style={{ color: theme.palette.primary.darkYellow, fontSize: "2rem" }}
      />
      {children}
    </Box>
  );
};
